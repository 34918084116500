<script setup lang="ts">
interface Props {
  title: string
  more?: string
  contentClass?: any
}

defineProps<Props>()

const { isRtl } = useLayout()
</script>

<template>
  <div>
    <div class="flex items-center">
      <div class="flex-1 lh-28px" text="18px md:24px">
        <Icon name="Lightning" class="mie-16px v-sub c-purple-5 lt-md:hidden" />
        <h2 class="inline-block c-#161636 font-900">
          {{ title }}
        </h2>
      </div>

      <NuxtLink v-if="more" :to="more" class="text-12px c-#161636 c-op-70 transition-all-100 md:text-16px active:c-purple-6 hover:c-purple-5 hover:c-op-100">
        <span>{{ $t('layout.more') }}</span>
        <span class="inline-block h-0 w-0 of-hidden">{{ title }}</span>
        <Icon name="ArrowDown" class="mis-4px" :class="isRtl ? 'rotate-90' : 'rotate--90'" />
      </NuxtLink>
    </div>

    <div class="rd-8px bg-#fff" mt="20px md:32px" :class="contentClass">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
