<template>
  <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.15" x="0.500122" width="52" height="52" rx="4" fill="currentcolor" />
    <path d="M25.8032 12.25C26.6316 12.25 27.3032 12.9216 27.3032 13.75V37.0445C27.3032 37.8729 26.6316 38.5445 25.8032 38.5445C24.9747 38.5445 24.3032 37.8729 24.3032 37.0445V13.75C24.3032 12.9216 24.9747 12.25 25.8032 12.25Z" fill="currentcolor" />
    <path d="M31.0976 16.1309C31.926 16.1309 32.5976 16.8024 32.5976 17.6309V31.5413L32.1513 32.0825C31.5371 32.8271 31.4453 33.7447 31.7281 34.522C31.5364 34.6109 31.3228 34.6605 31.0976 34.6605C30.2692 34.6605 29.5976 33.9889 29.5976 33.1605V17.6309C29.5976 16.8024 30.2692 16.1309 31.0976 16.1309Z" fill="currentcolor" />
    <path d="M37.0511 26.1417C37.2901 25.852 37.578 25.6315 37.8915 25.4802V21.5156C37.8915 20.6872 37.22 20.0156 36.3915 20.0156C35.5631 20.0156 34.8915 20.6872 34.8915 21.5156V28.7601L37.0511 26.1417Z" fill="currentcolor" />
    <path d="M15.2148 20.0156C16.0432 20.0156 16.7148 20.6872 16.7148 21.5156V29.2805C16.7148 30.1089 16.0432 30.7805 15.2148 30.7805C14.3864 30.7805 13.7148 30.1089 13.7148 29.2805V21.5156C13.7148 20.6872 14.3864 20.0156 15.2148 20.0156Z" fill="currentcolor" />
    <path d="M22.0092 17.6309C22.0092 16.8024 21.3376 16.1309 20.5092 16.1309C19.6808 16.1309 19.0092 16.8024 19.0092 17.6309V33.1605C19.0092 33.9889 19.6808 34.6605 20.5092 34.6605C21.3376 34.6605 22.0092 33.9889 22.0092 33.1605V17.6309Z" fill="currentcolor" />
    <path d="M38.5936 27.4143C38.7936 27.1718 39.1651 27.1718 39.3651 27.4143L44.265 33.3551C44.534 33.6812 44.302 34.1732 43.8792 34.1732H41.3883V39.2489C41.3883 39.525 41.1644 39.7489 40.8883 39.7489H37.0705C36.7944 39.7489 36.5705 39.525 36.5705 39.2489V34.1732H34.0795C33.6568 34.1732 33.4248 33.6812 33.6938 33.3551L38.5936 27.4143Z" fill="currentcolor" />
  </svg>
</template>
