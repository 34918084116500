<script setup lang="ts">
definePageMeta({
  middleware: () => {
    const { setConf } = useLayout()

    setConf({
      footBar: {
        visible: true,
      },
    })
  },
})

const { isWindows } = useDevice()

const { getXLink } = useDownload()
const link = getXLink()

function highlightEmail(text: string) {
  return text.replace(/\b\w+@\w+\.\w+\b/g, '<span class="c-purple-5 font-700">$&</span>')
}

const { aboutHead } = usePageHead()
aboutHead()

const { autoSideBar } = useLayout()
autoSideBar()

useCacheRoute()
</script>

<template>
  <div v-track="{ id: 'web-about', view: { value: { page: 'other-about' } } }" class="view-content pb-80px">
    <h1 class="text-center c-#161636 font-900" lt-md="mt-32px mb-40px text-28px" md="mt-60px mb-28px text-40px">
      {{ $t('layout.about_us') }}
    </h1>

    <p class="mb-40px text-center c-#161636 c-op-70" lt-md="text-16px lh-24px" md="text-18px lh-26px" v-html="$t('layout.about_desc')" />

    <ClientOnly>
      <div v-if="isWindows" class="mb-40px flex-center">
        <a v-track="{ id: 'web-about-download', click: { value: { page: currentRouteName() } } }" class="ld-button h-60px min-w-280px rd-8px px-20px text-14px ld-button-purple" :href="link">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" class="c-purple-5" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="20" height="20" rx="4" fill="white" />
            <path d="M6 16L15 16" stroke="currentcolor" stroke-width="2" stroke-linecap="round" />
            <path d="M6.5 8.66667L10.5 12M10.5 12L14.5 8.66667M10.5 12V4" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <span class="mis-8px">{{ $t('layout.download_ld_x') }}</span>
        </a>
      </div>
    </ClientOnly>

    <div lt-md="flex lt-md:flex-col gap-20px items-center">
      <template v-for="step in 3" :key="step">
        <div
          flex="~ col-reverse shrink-0"
          class="[justify-content:start] items-center rd-8px all:transition-all-1500"
          lt-md="bg-clip-[padding-box,border-box] bg-origin-[padding-box,border-box] b b-solid b-transparent of-hidden text-center w-full px-20px bg-image-[linear-gradient(#fff,#fff),linear-gradient(300deg,transparent_71%,#fff3)]"
          :class="[step === 2 ? 'md:flex-row-reverse md:rtl:flex-row' : 'md:flex-row md:rtl:flex-row-reverse', step !== 1 && 'md:mt--52px']"
        >
          <div
            class="relative z-2 rd-8px" b="~ solid transparent" p="b-24px md:40px"
            bg="clip-[padding-box,border-box] origin-[padding-box,border-box]"
            :md="step === 2
              ? 'rd-lb-0 bg-image-[linear-gradient(#fff,#fff),linear-gradient(-300deg,transparent_71%,#fff3)]'
              : 'rd-rb-0 bg-image-[linear-gradient(#fff,#fff),linear-gradient(300deg,transparent_71%,#fff3)]'"
          >
            <h2 class="c-#161636 font-900" mb="8px md:20px" text="18px md:28px" lh="26px md:38px" v-html="$t(`home_page.introduce_title_${step}`)" />
            <span
              class="text-justify c-#161636 c-op-50 tracking-wide"
              text="14px md:16px" lh="20px md:24px"
              v-html=" $t(`home_page.introduce_des_${step}`)"
            />

            <div
              :md="step === 2 ? 'odd:left--30px scale-x--100' : 'right--30px'"
              class="absolute inset-be-0 size-30px bg-gradient-to-rt"
              stops="[#fff_0_50%,transparent_50%_100%]"
            />
          </div>

          <div class="relative aspect-ratio-59/56 w-full flex-shrink-0" md="aspect-ratio-165/137 max-w-600px w-50vw">
            <LdImage
              class="absolute-center aspect-ratio-165/137 w-100vw" md="max-w-600px w-50vw"
              :src="getPublicImg(`about/${step}.webp`)" alt=""
            />
          </div>
        </div>
      </template>
    </div>

    <i class="my-40px block h-1px w-full bg-#000 bg-op-12" />

    <div>
      <p class="title font-900" lt-md="mb-20px text-20px" md="mb-24px text-28px">
        {{ $t('layout.contact_us') }}
      </p>

      <div class="text-14px c-#161636 c-op-70 lh-18px" md="lh-24px text-18px mb-80px">
        <p mb="16px md:12px" v-html="highlightEmail($t('layout.contact_text1'))" />
        <p mb="16px md:12px" v-html="highlightEmail($t('layout.contact_text2'))" />
        <p>
          <span lt-md="block" v-html="highlightEmail($t('layout.contact_text3')) " />
          <span lt-md="mt-4px block" md="mis-8px">{{ $t('layout.contact_text4') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
