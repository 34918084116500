<script setup lang="ts">
interface Props {
  type: 'upcoming-games' | number
  name: string
}

defineProps<Props>()

const { toAppType } = useRouterToPage()
</script>

<template>
  <NuxtLink
    :to="toAppType(type, { hasReturnUrl: true })"
    class="h-22px inline-flex items-center rd-20px px-12px text-12px transition-all-100"
    :class="type === 'upcoming-games' ? 'bg-purple-5 c-#fff hover:bg-purple-4' : 'bg-#E8E8E8 c-purple-5 hover:bg-purple-5 hover:bg-op-15'"
  >
    {{ name }}
  </nuxtlink>
</template>

<style lang="scss" scoped>
</style>
