<script setup lang="ts">
interface Props {
  initVisible?: boolean
  q: string
  a: string
}

const props = withDefaults(defineProps<Props>(), {
  initVisible: false,
})

const visible = ref(props.initVisible)
</script>

<template>
  <div class="rd-8px bg-#fff" px="20px md:40px">
    <div class="group flex select-none items-center" lt-md="gap-16px py-16px text-16px lh-24px" md="gap-20px py-24px text-20px lh-28px" @click="visible = !visible">
      <h3 class="flex-1 font-800">
        <span class="c-purple-5">Q: </span>
        <span class="c-#161636 transition-all-100 group-hover:c-purple-5" v-html="q" />
      </h3>

      <Icon name="Close" class="c-purple-5 transition-all-300" :class="visible ? 'rotate-0' : 'rotate--45'" />
    </div>

    <LdFold :visible="visible">
      <i class="block h-1px w-full bg-#161636 bg-op-10" mb="16px md:24px" />
      <div class="c-#161636 c-op-70" lt-md="pb-16px text-14px lh-24px" md="pb-24px text-18px lh-28px" v-html="a" />
    </LdFold>
  </div>
</template>

<style lang="scss" scoped>
</style>
